export const HOST_OFFICE_LIST = '/api/v2/host/offices';
export const HOST_DATA = '/api/v2/host/infos';
export const HOST_PATCH_PROFILE = (id: number | string) => `/api/v2/host/infos/${id}/update_personal_info`;
export const HOST_PATCH_SETTINGS = (id: number | string) => `/api/v2/host/infos/${id}/update_settings`;
export const HOST_PATCH_COMPANY = `/api/v2/host/infos/update_company`;
export const HOST_PATCH_COMMUNITY = `/api/v2/host/infos/update_community`;
export const OFFICE_DATA = (officeId: number | string) => `/api/v2/host/offices/${officeId}/get_office`;
export const ADD_CONTACT_INTENT = (officeId: number | string) => `/api/v2/offices/${officeId}/add_contact_intent`;
export const OFFICE_EDIT_BASIC_LISTING = (officeId: number | string) =>
  `/api/v2/host/offices/${officeId}/update_basic_listing`;
export const OFFICE_EDIT_AVAILABILITY = (officeId: number | string) =>
  `/api/v2/host/offices/${officeId}/update_availability`;
export const OFFICE_EDIT_SERVICES = (officeId: number | string) => `/api/v2/host/offices/${officeId}/update_services`;
export const OFFICE_EDIT_UPDATE_DETAILS = (officeId: number | string) =>
  `/api/v2/host/offices/${officeId}/update_details`;

export const SWITCH_LOCALE = '/api/v2/switch_locale';

// contract api
export const INIT_CONTRACT = (id: number | string) => `/api/v2/requests/${id}/init_contract`;
export const GET_CONTRACT = (id: number | string) => `/api/v2/contracts/${id}/get_contract`;
export const GET_CONTRACT_INFO = (id: number | string) => `/api/v2/contracts/${id}/get_contract_info`;
export const REJECT_CONTRACT_INFO = (id: number | string) => `/api/v2/contracts/${id}/reject_contract`;
export const UPDATE_USER_CONTRACT_INFO = (id: number | string) => `/api/v2/contracts/${id}/update_user_information`;
export const UPDATE_ORDER_CONTRACT_INFO = (id: number | string) => `/api/v2/contracts/${id}/update_order`;
export const UPDATE_PRICE_CONTRACT_INFO = (id: number | string) => `/api/v2/contracts/${id}/update_price`;
export const UPDATE_STEP_CONTRACT_INFO = (id: number | string) => `/api/v2/contracts/${id}/update_step`;
export const UPDATE_DOCUMENT_CONTRACT_INFO = (id: number | string) => `/api/v2/contracts/${id}/update_document`;
export const GET_DOCUMENT_CONTRACT_INFO = (id: number | string) => `/api/v2/contracts/${id}/all_documents`;
export const UPDATE_OFFICE_INFO = (id: number | string) => `/api/v2/offices/update_amenities/${id}`;
export const VALIDATE_CONTRACT_INFO = (id: number | string) => `/api/v2/contracts/${id}/validate_contract`;

//payment api
export const SUBSCRIBE_TO_PAYMENT = (id: number | string) => `/api/v2/payment_setups/${id}/subscribe`;
export const PAY_DEPOSIT = (id: number | string) => `/api/v2/payment_setups/${id}/pay_deposit`;

//user
export const GET_USER_DATA = '/api/v2/users/get_user_data';
export const OTP_VERIFY_USER = '/api/v2/twilio/verify_user';
export const OTP_VALIDATE = '/api/v2/twilio/verify_otp';
export const VERIFY_USER_PRESENCE = '/api/v2/users/verify_user_presence';

//twilio
export const SEND_OTP = '/api/v2/twilio/send_otp';
export const VERIFY_OTP = '/api/v2/twilio/verify_otp';

//wizard
export const FORM_DATA_API = '/api/v2/form_data';
export const HOST_DASHBOARD = '/dashboard/host/overview';
export const HOST_OFFICE_EDIT = (id: number) => `/host-dashboard/offices/${id}/ListingBasics`;
export const FORM_DATA_URL = (form: string, step: string) => `/api/v2/form_data/${form}/${step}`;

//search
export const OFFICE_SEARCH = '/api/v2/searches/offices';
export const LOCATION_LIST_API = '/api/v2/gmap_locations/auto_complete_api';

//office detail
export const OFFICE_PUBLIC_DATA = (officeId: number | string | undefined, showOriginal: boolean = false) =>
  `/api/v2/offices/${officeId}${showOriginal ? ' ?show_original=true' : ''}`;

//bookmarks
export const BOOKMARKS_DATA = '/api/v2/bookmarks';
export const INTERNAL_NOTES = '/api/v2/internal_notes';

//request
export const REQUEST_DATA = '/api/v2/requests/new';
export const REQUESTS = '/api/v2/requests';
export const UPDATE_COMPANY = '/api/v2/requests/update_company';

//sitemap
export const SITEMAP_DATA = '/api/v2/sitemap';

//mixpanel
export const MIXPANEL_TRACK = '/api/v2/mpevent';
